<template>
  <el-select
    v-model="colorModel"
    @change="colorChanged"
    
    :filterable="filterable"
    :disabled="disabled"
    :placeholder="placeholder"
  >
    <el-option :value="null" :label="placeholder"> </el-option>
    <el-option
      v-for="(color, key) in VEHICLE_COLORS"
      :key="key"
      :label="`${$t('VEHICLES.COLOR_' + key)}`"
      :value="key"
    />
  </el-select>
</template>

<script>
import _ from "lodash";
import { Option, Select } from "element-ui";
import { VEHICLE_COLORS } from "../constants/vehicles";

export default {
  name: "vehicle-color-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    value: {
      type: String,
      default: () => "",
      description: "Value",
    },
    placeholder: {
      type: String,
      default: () => "",
      description: "Placeholder",
    }
  },

  data() {
    const colorModel = this.value;
    return {
      colorModel,
      VEHICLE_COLORS,
    };
  },

  setup() {},

  created() {},

  computed: {},

  methods: {
    colorChanged(color) {
      this.$emit("colorChanged", color);
    },
  },

  watch: {
    value() {
      this.colorModel = this.value;
    },
  },
};
</script>
