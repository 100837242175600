var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vehicle-form"},[_c('div',{staticClass:"steps-header"},[_c('el-steps',{attrs:{"active":_vm.activeStep,"finish-status":"success"}},[_c('el-step',{attrs:{"title":"Etape 1"}}),_c('el-step',{attrs:{"title":"Etape 2"}}),_c('el-step',{attrs:{"title":"Etape 3"}}),_c('el-step',{attrs:{"title":"Etape 4"}})],1)],1),_c('div',{staticClass:"steps-content"},[_c('div',{staticClass:"vehicle-form-title"},[_c('h3',[_vm._v(_vm._s(_vm.$t("VEHICLES.VEHICLE_IDENTIFICATION")))])]),_c(_vm.currentStepComponent,{ref:"currentForm",tag:"component",attrs:{"vehicle-data":_vm.vehicle,"form-errors":_vm.formErrors,"loading":_vm.loading,"disable":_vm.disable},on:{"formChanged":(form) => {
          _vm.console.log('form changed', form);

          _vm.vehicle = { ..._vm.vehicle, ...form };

          _vm.onFormChanged();
        },"submitted":_vm.handleSubmit}})],1),_c('div',{staticClass:"steps-footer"},[_c('el-button',{staticClass:"prev",attrs:{"loading":_vm.loading,"disable":_vm.disable || _vm.activeStep === 0},on:{"click":_vm.prevStep}},[_c('i',{staticClass:"fal fa-arrow-left"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.PREVIOUS"))+" ")]),_c('el-button',{staticClass:"next",attrs:{"type":"primary","loading":_vm.loading,"disable":_vm.disable},on:{"click":_vm.nextStep}},[_vm._v(" "+_vm._s(_vm.activeStep === 3 ? _vm.saveButtonText : _vm.$t("COMMON.NEXT"))+" "),_c('i',{staticClass:"fal fa-arrow-right"})])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }