var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('TRANSACTIONS.VEHICLE')} (*)`,"placeholder":_vm.$t('TRANSACTIONS.VEHICLE')}},[_c('vehicle-selector',{attrs:{"vehicle":_vm.transaction.vehicle.id,"filterable":true,"solded":true,"auction-status":"completed","showAll":false},on:{"vehicleChanged":(vehicleId, vehicle) => {
            _vm.transaction.vehicle.id = vehicleId;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.vehicle}})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('TRANSACTIONS.ORGANIZATION_SELLER')} (*)`,"placeholder":_vm.$t('TRANSACTIONS.ORGANIZATION_SELLER')}},[_c('organization-selector',{attrs:{"organization":_vm.transaction.seller.id,"filterable":true,"showAll":false,"isSeller":true,"seller":_vm.transaction.seller.id,"buyer":_vm.transaction.buyer.id,"disabled":!!_vm.transaction.id},on:{"organizationChanged":(organizationId, organization) => {
              _vm.transaction.seller.id = organizationId;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.seller}})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('TRANSACTIONS.LOCATION_SELLER')} (*)`,"placeholder":_vm.$t('TRANSACTIONS.LOCATION_SELLER')}},[_c('location-selector',{attrs:{"location":_vm.transaction.sellerLocation.id,"filterable":true,"showAll":false,"disabled":!_vm.transaction.vehicle.id,"organization":_vm.transaction.seller.id},on:{"locationChanged":(locationId, location) => {
            _vm.transaction.sellerLocation.id = locationId;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.sellerLocation}})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('TRANSACTIONS.ORGANIZATION_BUYER')} (*)`,"placeholder":_vm.$t('TRANSACTIONS.ORGANIZATION_BUYER')}},[_c('organization-selector',{attrs:{"organization":_vm.transaction.buyer.id,"filterable":true,"showAll":false,"isBuyer":true,"seller":_vm.transaction.seller.id,"buyer":_vm.transaction.buyer.id,"disabled":!!_vm.transaction.id},on:{"organizationChanged":(organizationId, organization) => {
              _vm.transaction.buyer.id = organizationId;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.buyer}})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('TRANSACTIONS.LOCATION_BUYER')} (*)`,"placeholder":_vm.$t('TRANSACTIONS.LOCATION_BUYER')}},[_c('location-selector',{attrs:{"location":_vm.transaction.buyerLocation.id,"filterable":true,"showAll":false,"disabled":!_vm.transaction.buyer.id,"organization":_vm.transaction.buyer.id},on:{"locationChanged":(locationId, location) => {
            _vm.transaction.buyerLocation.id = locationId;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.buyerLocation}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('TRANSACTIONS.PRICE')}`,"placeholder":_vm.$t('TRANSACTIONS.PRICE')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.transaction.price),callback:function ($$v) {_vm.$set(_vm.transaction, "price", $$v)},expression:"transaction.price"}})],1),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.transaction.id ? _vm.$t("TRANSACTIONS.EDIT_TRANSACTION") : _vm.$t("TRANSACTIONS.ADD_TRANSACTION"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }