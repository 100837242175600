<template>
  <div class="vehicle-images">
    <div class="vehicle-images-title">
      <h3>
        {{ $objectDenomination(vehicle) }} - {{ $t("VEHICLES.PICTURES") }}
      </h3>
    </div>

    <div class="vehicle-images-list">
      <div class="vehicle-images-list-item">
        <p>{{ $t("VEHICLES.EXTERIOR_PHOTOS") }}</p>
        <div class="images">
          <div
            class="images-item"
            v-for="(imageUrl, idx) in vehicle.exterior_photos"
            :key="`vehicle-${vehicle.id}-${idx}`"
          >
            <img :src="imageUrl" alt="logo" />
          </div>
        </div>
      </div>
      <div class="vehicle-images-list-item">
        <p>{{ $t("VEHICLES.INTERIOR_PHOTOS") }}</p>
        <div class="images">
          <div
            class="images-item"
            v-for="(imageUrl, idx) in vehicle.interior_photos"
            :key="`vehicle-${vehicle.id}-${idx}`"
          >
            <img :src="imageUrl" alt="logo" />
          </div>
        </div>
      </div>
      <div class="vehicle-images-list-item">
        <p>{{ $t("VEHICLES.DAMAGES") }}</p>
        <ul>
          <li v-for="(d, idx) in vehicle.damages" :key="idx">
            <img :src="d.photo" alt="logo" />
            <span>{{ d.location }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { QUERY_ACTIONS_VIEW } from "@/constants/common";
import Collapse from "@/components/Collapse/Collapse";
import CollapseItem from "@/components/Collapse/CollapseItem";

export default {
  name: "vehicle-view-images",

  components: { [Collapse.name]: Collapse, [CollapseItem.name]: CollapseItem },

  props: ["vehicle"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    vehicle(vehicle) {},
  },
};
</script>
