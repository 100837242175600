<template>
  <el-select
    v-model="carTypeModel"
    @change="carTypeChanged"
    :filterable="filterable"
    :disabled="disabled"
    :placeholder="`${$t('VEHICLES.CAR_TYPE')}`"
  >
    <el-option :value="null" :label="$t('VEHICLES.CAR_TYPE')"></el-option>
    <el-option
      v-for="item in VEHICLE_CAR_TYPES"
      :key="item"
      :label="`${$t('VEHICLES.CAR_TYPE_' + item.replace(' ', '_'))}`"
      :value="item"
    />
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";
import { VEHICLE_CAR_TYPES } from "@/constants/vehicles";

export default {
  name: "car-type-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    value: {
      type: String,
      default: () => "",
      description: "Value",
    },
  },

  data() {
    const carTypeModel = this.value;
    return {
      carTypeModel,
      VEHICLE_CAR_TYPES,
    };
  },

  setup() {},

  created() {},

  computed: {},

  methods: {
    carTypeChanged(carType) {
      this.$emit("carTypeChanged", carType);
    },
  },

  watch: {
    value() {
      this.carTypeModel = this.value;
    },
  },
};
</script>
