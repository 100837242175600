<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <h3>
        {{ `${vehicle.make} ${vehicle.model} - ${vehicle.series}` }} -
        {{ $t("COMMON.LOGS") }}
      </h3>
    </div>
    <log-list-table :filterSubject="vehicle" />
  </div>
</template>
<script>
import LogListTable from "@/views/Pages/DefaultModule/LogManagement/partials/LogListTable.vue";

export default {
  name: "vehicle-view-logs",

  components: { LogListTable },

  props: ["vehicle"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>
