export default {
  type: "transactions",
  price: "",
  relationshipNames: ["seller", "buyer", "sellerLocation", "buyerLocation", "vehicle"],
  seller: {
    type: "organizations",
    id: null,
  },
  buyer: {
    type: "organizations",
    id: null,
  },
  sellerLocation: {
    type: "locations",
    id: null,
  },
  buyerLocation: {
    type: "locations",
    id: null,
  },
  vehicle: {
    type: "vehicles",
    id: null,
  },
};
