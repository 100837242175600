<template>
  <el-select
    v-model="drivetrainModel"
    @change="drivetrainChanged"
    :placeholder="$t('VEHICLES.TRANSMISSION')"
    :filterable="filterable"
    :disabled="disabled"
  >
    <el-option :value="null" :label="$t('VEHICLES.TRANSMISSION')"> </el-option>
    <el-option
      v-for="item in VEHICLE_DRIVETRAIN_LIST"
      :key="item"
      :label="item"
      :value="item"
    />
  </el-select>
</template>

<script>
import _ from "lodash";
import { Option, Select } from "element-ui";
import { VEHICLE_DRIVETRAIN_LIST } from "../constants/vehicles";

export default {
  name: "drivetrain-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    value: {
      type: String,
      default: () => "",
      description: "Value",
    },
  },

  data() {
    const drivetrainModel = this.value;
    return {
      drivetrainModel,
      VEHICLE_DRIVETRAIN_LIST,
    };
  },

  setup() {},

  created() {},

  computed: {},

  methods: {
    drivetrainChanged(drivetrain) {
      this.$emit("drivetrainChanged", drivetrain);
    },
  },

  watch: {
    value() {
      this.drivetrainModel = this.value;
    },
  },
};
</script>
