<template>
  <el-select
    v-model="fuelModel"
    @change="fuelTypeChanged"
    :placeholder="$t('VEHICLES.FUEL_TYPE')"
    :filterable="filterable"
    :disabled="disabled"
  >
    <el-option :value="null" :label="$t('VEHICLES.FUEL_TYPE')"> </el-option>
    <el-option
      v-for="(v, k) in FUEL_TYPES"
      :key="k"
      :value="v"
      :label="`${$t('FUEL_TYPES.' + k)}`"
    />
  </el-select>
</template>

<script>
import _ from "lodash";
import { Option, Select } from "element-ui";
import { FUEL_TYPES } from "@/constants/fuelTypes";

export default {
  name: "fuel-type-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    value: {
      type: String | Number | null | undefined,
      default: "",
      description: "Value",
    },
    options: {
      type: Array | undefined | null,
      default: null,
    },
  },

  data() {
    const fuelModel = this.value?.toLowerCase();
    return {
      fuelModel,
      FUEL_TYPES,
    };
  },

  setup() {},

  created() {},

  computed: {
    fuelTypesOptions: function () {
      if (Array.isArray(this.options) && this.options.length > 0) {
        return this.options.map((value) => ({
          value,
          label: this.$t(`FUEL_TYPES.${value.toUpperCase()}`),
        }));
      }

      return Object.entries(this.fuelTypes).map(([key, value]) => ({
        value,
        label: this.$t(`FUEL_TYPES.${key}`),
      }));
    },
  },

  methods: {
    fuelTypeChanged(fuel) {
      this.$emit("fuelTypeChanged", fuel);
    },
  },

  watch: {
    value() {
      this.fuelModel = this.value?.toLowerCase();
    },
  },
};
</script>
