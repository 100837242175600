<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!vehicle">
      <span class="loader"></span>
    </span>

    <vehicle-form
      ref="form"
      v-if="vehicle"
      :loading="loading"
      :vehicle-data="vehicle"
      :form-errors="formErrors"
      @vehicleSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import VehicleForm from "../partials/VehicleForm.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { VEHICLE_STATUS_COMPLETED } from "@/constants/vehicles";

export default {
  layout: "DashboardLayout",

  components: { VehicleForm },

  mixins: [alertLeave, requestErrorMixin],

  props: {
    vehicleId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      vehicle: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    console.log("watch created");

    this.get();
  },

  methods: {
    async get() {
      this.loading = true;

      try {
        await this.$store.dispatch("vehicles/get", this.vehicleId);

        this.vehicle = this.$store.getters["vehicles/vehicle"];

        this.loading = false;
      } catch (error) {
        console.warn("error", error);

        this.showRequestError(error);

        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        this.loading = false;
      }
    },
    async handleSubmit(vehicle) {
      this.loading = true;

      // const vehicleData = {
      //   ...cloneDeep(vehicle),
      //   // auction_date: vehicle.auction_date
      //   //   ? moment(vehicle.auction_date).toISOString()
      //   //   : null,
      // };

      const vehicleData = cloneDeep(vehicle);

      console.log("vehicle data", vehicleData);

      try {
        await this.$store.dispatch("vehicles/update", vehicleData);

        this.$notify({
          type: "success",
          message: this.$t("VEHICLES.VEHICLE_UPDATED"),
        });

        this.vehicle = this.$store.getters["vehicles/vehicle"];

        console.log("updated vehicle data", this.vehicle);

        if (
          this.$refs.form.activeStep === 3 &&
          this.vehicle.status.toLowerCase() ===
            VEHICLE_STATUS_COMPLETED.toLowerCase()
        ) {
          this.$emit("onViewVehicle", this.vehicle, true);
        } else {
          this.$refs.form.activeStep++;
        }

        this.loading = false;
      } catch (error) {
        console.log("error changed", error.response?.data?.errors);

        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        this.formErrors = error.response?.data?.errors ?? {};

        this.loading = false;
      }
    },
  },
};
</script>
