<template>
  <el-select
    v-model="tireConditionModel"
    @change="tireConditionChanged"
    :placeholder="$t('VEHICLES.TIRE_CONDITION')"
    :filterable="filterable"
    :disabled="disabled"
  >
    <el-option :value="null" :label="$t('VEHICLES.TIRE_CONDITION')">
    </el-option>
    <el-option
      v-for="item in VEHICLE_CONDITION_LIST"
      :key="item"
      :label="`${$t('VEHICLES.CONDITION_' + item)}`"
      :value="item"
    />
  </el-select>
</template>

<script>
import _ from "lodash";
import { Option, Select } from "element-ui";
import { VEHICLE_CONDITION_LIST } from "../constants/vehicles";

export default {
  name: "tire-tireCondition-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    value: {
      type: String | Number | null | undefined,
      default: "",
      description: "Value",
    },
    options: {
      type: Array | undefined | null,
      default: null,
    },
  },

  data() {
    const tireConditionModel = this.value;
    return {
      tireConditionModel,
      VEHICLE_CONDITION_LIST,
    };
  },

  setup() {},

  created() {},

  computed: {},

  methods: {
    tireConditionChanged(tireCondition) {
      this.$emit("tireConditionChanged", tireCondition);
    },
  },

  watch: {
    value() {
      this.tireConditionModel = this.value;
    },
  },
};
</script>
