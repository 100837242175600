<template>
  <div class="tab-pane-inner">
    <div class="inspections-list form-inspection">
      <div class="inspection-title">
        <span>
          {{ $t("COMMON.BIDS") }}
        </span>
      </div>

      <bid-list-table :filter-vehicle="vehicle.id" />
    </div>
  </div>
</template>

<script>
import BidListTable from "./BidListTable.vue";

export default {
  name: "vehicle-view-bids",

  components: { BidListTable },

  props: ["vehicle"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    vehicle(vehicle) {},
  },
};
</script>
