var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"form",staticClass:"vehicle-form-inner",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.vehicle.organization?.id,"filterable":true,"showAll":false,"disabled":_vm.disable,"required":""},on:{"organizationChanged":(organizationId) => {
            _vm.vehicle.organization = {
              id: organizationId,
              type: 'organizations',
            };
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('location-selector',{attrs:{"location":_vm.vehicle.allowedLocations[0]?.id,"organization":_vm.vehicle.organization?.id,"disabled":_vm.disable},on:{"locationChanged":(locationId) => {
            _vm.vehicle.allowedLocations = [
              { type: 'locations', id: locationId },
            ];
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}})],1):_vm._e(),_c('div',{staticClass:"inspection-form-group width-1 has-button"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.SEARCH_BY_VIN')}`,"placeholder":_vm.$t('VEHICLES.SEARCH_BY_VIN')}},[_c('vehicle-api-by-vin-selector',{attrs:{"filterOrganization":_vm.vehicle.organization?.id,"disabled":_vm.disable,"vin":_vm.vehicle.vin,"clearable":""},on:{"vehicleSelected":_vm.onVehicleSelected}})],1),_c('base-button',{staticClass:"expand",attrs:{"icon":"","size":"sm"}},[_c('i',{staticClass:"far fa-expand"})])],1),_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.VIN')} `,"disabled":_vm.disable,"required":true,"placeholder":_vm.$t('VEHICLES.VIN')},model:{value:(_vm.vehicle.vin),callback:function ($$v) {_vm.$set(_vm.vehicle, "vin", $$v)},expression:"vehicle.vin"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.vin}})],1),_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.SKU')} `,"placeholder":_vm.$t('VEHICLES.SKU'),"disabled":_vm.disable},model:{value:(_vm.vehicle.sku),callback:function ($$v) {_vm.$set(_vm.vehicle, "sku", $$v)},expression:"vehicle.sku"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.sku}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.MODEL_YEAR')}`,"disable":_vm.disable,"required":true,"placeholder":`${_vm.$t('VEHICLES.MODEL_YEAR')}`},model:{value:(_vm.vehicle.model_year),callback:function ($$v) {_vm.$set(_vm.vehicle, "model_year", $$v)},expression:"vehicle.model_year"}},[_c('year-selector',{attrs:{"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.model_year},on:{"yearChanged":(year) => {
            _vm.vehicle.model_year = year;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.model_year}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.MAKE')}`,"placeholder":`${_vm.$t('VEHICLES.MAKE')}`,"disable":_vm.disable,"required":true},model:{value:(_vm.vehicle.make),callback:function ($$v) {_vm.$set(_vm.vehicle, "make", $$v)},expression:"vehicle.make"}},[_c('make-selector',{attrs:{"year":_vm.vehicle.model_year,"disabled":_vm.disable,"filterable":true,"placeholder":_vm.$t('VEHICLES.MAKE'),"value":_vm.vehicle.make},on:{"makeChanged":(make) => {
            _vm.vehicle.make = make;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.make}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.MODEL')}`,"placeholder":`${_vm.$t('VEHICLES.MODEL')}`,"disable":_vm.disable,"required":true},model:{value:(_vm.vehicle.model),callback:function ($$v) {_vm.$set(_vm.vehicle, "model", $$v)},expression:"vehicle.model"}},[_c('model-selector',{attrs:{"year":_vm.vehicle.model_year,"disabled":_vm.disable,"filterable":true,"make":_vm.vehicle.make,"value":_vm.vehicle.model},on:{"modelChanged":(model) => {
            _vm.vehicle.model = model;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.model}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.SERIES')}`,"placeholder":_vm.$t('VEHICLES.SERIES'),"disabled":_vm.disable},model:{value:(_vm.vehicle.series),callback:function ($$v) {_vm.$set(_vm.vehicle, "series", $$v)},expression:"vehicle.series"}},[_c('serie-selector',{attrs:{"year":_vm.vehicle.model_year,"make":_vm.vehicle.make,"disabled":_vm.disable,"model":_vm.vehicle.model,"filterable":true,"value":_vm.vehicle.series},on:{"serieChanged":(serie) => {
            _vm.vehicle.series = serie;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.series}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.STYLE')}`,"placeholder":_vm.$t('VEHICLES.STYLE'),"disabled":_vm.disable},model:{value:(_vm.vehicle.style),callback:function ($$v) {_vm.$set(_vm.vehicle, "style", $$v)},expression:"vehicle.style"}},[_c('style-selector',{attrs:{"year":_vm.vehicle.model_year,"make":_vm.vehicle.make,"model":_vm.vehicle.model,"serie":_vm.vehicle.serie,"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.style},on:{"styleChanged":(style) => {
            _vm.vehicle.style = style;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.style}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.ENGINE_DESCRIPTION')}`,"placeholder":`${_vm.$t('VEHICLES.ENGINE_DESCRIPTION')}`,"disable":_vm.disable,"required":true},model:{value:(_vm.vehicle.engine_description),callback:function ($$v) {_vm.$set(_vm.vehicle, "engine_description", $$v)},expression:"vehicle.engine_description"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.engine_description}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.FUEL_TYPE')} `,"placeholder":_vm.$t('VEHICLES.FUEL_TYPE'),"disabled":_vm.disable},model:{value:(_vm.vehicle.fuel_type),callback:function ($$v) {_vm.$set(_vm.vehicle, "fuel_type", $$v)},expression:"vehicle.fuel_type"}},[_c('fuel-type-selector',{attrs:{"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.fuel_type},on:{"fuelTypeChanged":(fuelType) => {
            _vm.vehicle.fuel_type = fuelType;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.fuel_type}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"type":"number","min":"0","label":`${_vm.$t('VEHICLES.ODOMETER')}`,"placeholder":`${_vm.$t('VEHICLES.ODOMETER')}`,"disable":_vm.disable,"required":true},model:{value:(_vm.vehicle.odometer_mileage),callback:function ($$v) {_vm.$set(_vm.vehicle, "odometer_mileage", $$v)},expression:"vehicle.odometer_mileage"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.odometer_mileage}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.ODOMETER_TYPE')}`,"placeholder":`${_vm.$t('VEHICLES.ODOMETER_TYPE')}`,"disable":_vm.disable,"required":true},model:{value:(_vm.vehicle.odometer_type),callback:function ($$v) {_vm.$set(_vm.vehicle, "odometer_type", $$v)},expression:"vehicle.odometer_type"}},[_c('odometer-type-selector',{attrs:{"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.odometer_type},on:{"odometerTypeChanged":(type) => {
            _vm.vehicle.odometer_type = type;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.odometer_type}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.CAR_TYPE')}`,"placeholder":`${_vm.$t('VEHICLES.CAR_TYPE')}`,"disable":_vm.disable,"required":true},model:{value:(_vm.vehicle.car_type),callback:function ($$v) {_vm.$set(_vm.vehicle, "car_type", $$v)},expression:"vehicle.car_type"}},[_c('car-type-selector',{attrs:{"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.car_type},on:{"carTypeChanged":(type) => {
            _vm.vehicle.car_type = type;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.car_type}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.FUEL_CAP')} (L)`,"placeholder":`${_vm.$t('VEHICLES.FUEL_CAP')}`},model:{value:(_vm.vehicle.fuel_cap),callback:function ($$v) {_vm.$set(_vm.vehicle, "fuel_cap", $$v)},expression:"vehicle.fuel_cap"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.fuel_cap}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.HORSE_POWER')}`,"placeholder":`${_vm.$t('VEHICLES.HORSE_POWER')}`,"disable":_vm.disable},model:{value:(_vm.vehicle.horse_power),callback:function ($$v) {_vm.$set(_vm.vehicle, "horse_power", $$v)},expression:"vehicle.horse_power"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.horse_power}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.TRANSMISSION')} `,"placeholder":_vm.$t('VEHICLES.TRANSMISSION'),"disabled":_vm.disable},model:{value:(_vm.vehicle.drivetrain),callback:function ($$v) {_vm.$set(_vm.vehicle, "drivetrain", $$v)},expression:"vehicle.drivetrain"}},[_c('drivetrain-selector',{attrs:{"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.drivetrain},on:{"drivetrainChanged":(drivetrain) => {
            _vm.vehicle.drivetrain = drivetrain;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.drivetrain}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.GEARBOX')} `,"placeholder":_vm.$t('VEHICLES.GEARBOX'),"disabled":_vm.disable},model:{value:(_vm.vehicle.transmission),callback:function ($$v) {_vm.$set(_vm.vehicle, "transmission", $$v)},expression:"vehicle.transmission"}},[_c('transmission-selector',{attrs:{"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.transmission},on:{"transmissionChanged":(transmission) => {
            _vm.vehicle.transmission = transmission;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.transmission}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"type":"number","min":"1","label":`${_vm.$t('VEHICLES.NB_PASSENGERS')}`,"placeholder":`${_vm.$t('VEHICLES.NB_PASSENGERS')}`,"disable":_vm.disable,"required":true},model:{value:(_vm.vehicle.nb_passengers),callback:function ($$v) {_vm.$set(_vm.vehicle, "nb_passengers", $$v)},expression:"vehicle.nb_passengers"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.nb_passengers}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"type":"number","min":"1","label":`${_vm.$t('VEHICLES.NB_DOORS')}`,"placeholder":`${_vm.$t('VEHICLES.NB_DOORS')}`,"disable":_vm.disable,"required":true},model:{value:(_vm.vehicle.nb_doors),callback:function ($$v) {_vm.$set(_vm.vehicle, "nb_doors", $$v)},expression:"vehicle.nb_doors"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.nb_doors}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.OUTSIDE_COLOR')}`,"placeholder":`${_vm.$t('VEHICLES.OUTSIDE_COLOR')}`,"disable":_vm.disable,"required":true},model:{value:(_vm.vehicle.outside_color),callback:function ($$v) {_vm.$set(_vm.vehicle, "outside_color", $$v)},expression:"vehicle.outside_color"}},[_c('vehicle-color-selector',{attrs:{"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.outside_color,"placeholder":`${_vm.$t('VEHICLES.OUTSIDE_COLOR')}`},on:{"colorChanged":(color) => {
            _vm.vehicle.outside_color = color;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.outside_color}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.INSIDE_COLOR')}`,"placeholder":`${_vm.$t('VEHICLES.INSIDE_COLOR')}`,"disable":_vm.disable,"required":true},model:{value:(_vm.vehicle.inside_color),callback:function ($$v) {_vm.$set(_vm.vehicle, "inside_color", $$v)},expression:"vehicle.inside_color"}},[_c('vehicle-color-selector',{attrs:{"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.inside_color},on:{"colorChanged":(color) => {
            _vm.vehicle.inside_color = color;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.inside_color}})],1),_c('div',{staticClass:"inspection-form-group width-1"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.ACCESSORIES')}`,"placeholder":`${_vm.$t('VEHICLES.ACCESSORIES')}`,"disable":_vm.disable,"required":true}},[_c('accessories-selector',{attrs:{"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.accessories ?? []},on:{"accessoriesChanged":(accessories) => {
            _vm.vehicle.accessories = accessories;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.accessories}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }