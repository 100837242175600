<template>
  <el-select
    v-model="vehicleModel"
    @change="vehicleChanged"
    autocomplete="new-password"
    :placeholder="$t('TRANSACTIONS.VEHICLE')"
    :filterable="true"
    :multiple="false"
    :disabled="disabled"
    remote
    :remote-method="getVehicles"
    :loading="loading"
  >
    <el-option
      v-for="vehicle in vehicles"
      :key="vehicle.id"
      :value="vehicle.id"
      :label="`${vehicle.model_year} ${vehicle.make} ${vehicle.model} ${vehicle.series ?? ''} ${vehicle.style} ${vehicle.engine_description} ${vehicle.fuel_type}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { difference } from "lodash";
import { Option, Select } from "element-ui";

export default {
  name: "vehicle-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    solded: {
      type: Boolean,
      default: false,
      description: "solded the input",
    },
    auctionStatus: {
      type: String,
      default: 'completed',
      description: "auction status the input",
    },
    vehicle: {
      type: String,
      default: null,
      description: "Vehicle id",
    },
    organization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      vehicleModel: this.vehicle,
      vehicles: {},
    };
  },

  setup() {},

  created() {
    this.getVehicles(null, this.vehicle);
  },

  methods: {
    async getVehicles(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "auction_end_date",
          filter: {
            ...(query ? { search: query } : {}),
            ...(id ? { id: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.solded) {
          params = {
           ...params,
            filter: { ...params.filter, auction_closed: 1 },
          };
        }
        if (this.auctionStatus) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              auction_status: this.auctionStatus,
            },
          };
        }
        await this.$store.dispatch("vehicles/list", params);
        const vehiclesArr = await this.$store.getters["vehicles/list"];
        this.vehicles = {};
        vehiclesArr.forEach((vehicle) => {
          this.vehicles[vehicle.id] = vehicle;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    vehicleChanged(vehicleId) {
      const vehicle = Object.values(this.vehicles).find(
        (item) => item.id === vehicleId
      );
      this.$emit("vehicleChanged", vehicleId, vehicle);
    },
  },

  watch: {
    vehicle(vehicle) {
      if (vehicle) {
        this.vehicleModel = vehicle;
        if (vehicle !== this.vehicleModel) {
          this.getVehicles(null, vehicle);
        }
      } else {
        this.locationModel = null;
      }
    },
    organization(organization) {
      this.getVehicles();
    },
  },
};
</script>
