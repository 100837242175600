var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"form",staticClass:"vehicle-form-inner",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.PICKUP_LOCATION')}`,"placeholder":`${_vm.$t('VEHICLES.PICKUP_LOCATION')}`}},[_c('addresses-selector',{attrs:{"address":_vm.vehicle.pickup_location},on:{"addressInputChangeChanged":(values) => {
            _vm.vehicle.pickup_location = values.formatted_address ?? '';
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.pickup_location}})],1),_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"label":`${_vm.$t('AUCTIONS.AUCTION_TYPE')}`,"disable":_vm.disable,"required":true,"placeholder":`${_vm.$t('AUCTIONS.AUCTION_TYPE')}`},model:{value:(_vm.vehicle.auction_type),callback:function ($$v) {_vm.$set(_vm.vehicle, "auction_type", $$v)},expression:"vehicle.auction_type"}},[_c('auction-type-selector',{attrs:{"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.auction_type},on:{"auctionTypeChanged":(type) => {
            _vm.vehicle.auction_type = type;
            _vm.loadEvaluationAuctionDeadline();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.auction_type}})],1),(
      [_vm.AUCTION_TYPE_EVALUATION, _vm.AUCTION_TYPE_TIMED].includes(
        _vm.vehicle.auction_type
      )
    )?_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.AUCTION_END_DATE')} (*)`,"placeholder":_vm.$t('VEHICLES.AUCTION_END_DATE')},model:{value:(_vm.vehicle.auction_end_date),callback:function ($$v) {_vm.$set(_vm.vehicle, "auction_end_date", $$v)},expression:"vehicle.auction_end_date"}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"placeholder":_vm.$t('VEHICLES.AUCTION_END_DATE'),"config":{
          allowInput: true,
          enableTime: true,
          type: 'datetime',
          locale: _vm.$flatPickrLocale(),
          minDate: 'today',
        }},model:{value:(_vm.vehicle.auction_end_date),callback:function ($$v) {_vm.$set(_vm.vehicle, "auction_end_date", $$v)},expression:"vehicle.auction_end_date"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.auction_end_date}})],1):_vm._e(),([_vm.AUCTION_TYPE_EVALUATION].includes(_vm.vehicle.auction_type))?_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.SALE_CONFIRMATION_DATE')} (*)`,"placeholder":_vm.$t('VEHICLES.SALE_CONFIRMATION_DATE')},model:{value:(_vm.vehicle.sale_confirmation_date),callback:function ($$v) {_vm.$set(_vm.vehicle, "sale_confirmation_date", $$v)},expression:"vehicle.sale_confirmation_date"}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"placeholder":_vm.$t('VEHICLES.SALE_CONFIRMATION_DATE'),"config":{
          allowInput: true,
          enableTime: true,
          type: 'datetime',
          locale: _vm.$flatPickrLocale(),
          minDate: _vm.vehicle.auction_end_date,
          disable: [
            function (date) {
              return !_vm.vehicle.auction_end_date;
            },
          ],
        }},model:{value:(_vm.vehicle.sale_confirmation_date),callback:function ($$v) {_vm.$set(_vm.vehicle, "sale_confirmation_date", $$v)},expression:"vehicle.sale_confirmation_date"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.sale_confirmation_date}})],1):_vm._e(),_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"type":"number","label":`${_vm.$t('VEHICLES.BUY_NOW_PRICE')}`,"placeholder":`${_vm.$t('VEHICLES.BUY_NOW_PRICE')}`,"disable":_vm.disable},model:{value:(_vm.vehicle.buy_now_price),callback:function ($$v) {_vm.$set(_vm.vehicle, "buy_now_price", $$v)},expression:"vehicle.buy_now_price"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.buy_now_price}})],1),_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"type":"number","label":`${_vm.$t('VEHICLES.MIN_BID_AMOUNT')}`,"placeholder":`${_vm.$t('VEHICLES.MIN_BID_AMOUNT')}`,"disable":_vm.disable,"required":""},model:{value:(_vm.vehicle.min_bid_amount),callback:function ($$v) {_vm.$set(_vm.vehicle, "min_bid_amount", $$v)},expression:"vehicle.min_bid_amount"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.min_bid_amount}})],1),_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"type":"number","label":`${_vm.$t('VEHICLES.RESERVE_PRICE')}`,"placeholder":`${_vm.$t('VEHICLES.RESERVE_PRICE')}`,"disable":_vm.disable,"required":""},model:{value:(_vm.vehicle.reserve_price),callback:function ($$v) {_vm.$set(_vm.vehicle, "reserve_price", $$v)},expression:"vehicle.reserve_price"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.reserve_price}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }